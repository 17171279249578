import { isNotNil } from '@shared/utils';

export const sortByTruthiness = <T>(a: T, b: T) =>
  !a === !b ? 0 : !a ? 1 : -1;

export const presortNils = <T>(
  a: T,
  b: T,
  sortFunction?: (a: NonNullable<T>, b: NonNullable<T>) => number,
) =>
  isNotNil(a) && isNotNil(b) ? sortFunction?.(a, b) : sortByTruthiness(a, b);
