import fastDeepEqual from 'fast-deep-equal';
import R from 'ramda';

const { curry, find, propEq, is } = R;

export const flatten = (array, key) =>
  array.reduce((acc, obj) => {
    if (is(Object, obj)) {
      acc.push(obj);

      if (obj[key]) {
        return acc.concat(flatten(obj[key], key));
      }
    }

    return acc;
  }, []);

export const findWhere = curry(
  (
    prop, //eslint-disable-line
    value,
    array = [],
  ) => find(propEq(prop, value), array),
);

export const findWhereId = curry((value, array) =>
  findWhere('id', +value, array),
);

export const denormById = R.curry((mappedData, obj) => {
  if (R.isNil(obj) || R.isEmpty(obj)) {
    return;
  }

  const ids = R.keys(mappedData);

  const denormalizeFunction = (value, key) => {
    const keyName = key.slice(0, -2);
    const denormValue = R.path([key, value], mappedData);

    if (!denormValue || key.slice(-2) !== 'Id') {
      return value;
    }

    return {
      [keyName]: denormValue,
    };
  };

  const denormalizedObject = R.compose(
    R.mergeAll,
    R.values,
  )(R.mapObjIndexed(denormalizeFunction, R.pick(ids, obj)));

  return R.merge(obj, denormalizedObject);
});

export const debounce = (func, time = 250) => {
  let interval;

  return (...args) => {
    clearTimeout(interval);
    interval = setTimeout(() => {
      interval = null;
      func(...args);
    }, time);
  };
};

export const round = (value, decimals = 2) => {
  if (typeof value !== 'number') {
    return value;
  }
  const number = 10 ** decimals;
  return Math.round(value * number) / number;
};

export const chunkArray = (array, size) => {
  const chunkedArray = [];
  let index = 0;

  while (index < array.length) {
    chunkedArray.push(array.slice(index, size + index));
    index += size;
  }

  return chunkedArray;
};

export const capitalizeFirstLetter = (string) =>
  string[0].toUpperCase() + string.slice(1).toLowerCase();

export const someIsNil = (...values) =>
  values.some((value) => value === null || value === undefined);
export const mockApiCall = (responseData, time = 1000, isRejection) =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        isRejection
          ? // eslint-disable-next-line prefer-promise-reject-errors
            reject(responseData)
          : resolve({ body: responseData }),

      time,
    );
  });

export const escapeRegExp = (regExp) =>
  regExp?.toString().replace(/[-[\]{}()*+!<=:?./\\^$|,]/g, '\\$&') || '';

export const generateNumericOptions = (numberOfOptions, startsWith = 1) =>
  [...new Array(numberOfOptions)].map((_, i) => ({
    value: i + startsWith,
    label: `${i + startsWith}`,
  }));

export const getTextExcerpt = (text, maxLength) => {
  if (typeof maxLength !== 'number' || text.length <= maxLength) {
    return text;
  }
  return `${text.substring(0, maxLength)}...`;
};

export const safeJsonParse = (value) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return null;
  }
};

export const isEqual = (a, b) => fastDeepEqual(a, b);

export const isNot = (x) => !x;

export const omit = (obj, keys) => {
  const result = { ...obj };
  keys.forEach((key) => {
    delete result[key];
  });
  return result;
};

export const asyncScrollIntoView = ({
  scrollingElement,
  element,
  scrollOptions = { behavior: 'smooth' },
  debounceTimer = 50,
}) =>
  new Promise((resolve) => {
    let timer;

    const handleScroll = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        resolve();
        scrollingElement.removeEventListener('scroll', handleScroll);
      }, debounceTimer);
    };

    scrollingElement.addEventListener('scroll', handleScroll, {
      passive: true,
    });
    element.scrollIntoView(scrollOptions);
  });

export const clamp = (value, min, max) => Math.max(min, Math.min(value, max));

export const callAll =
  (...fns) =>
  (...args) =>
    fns.forEach((fn) => fn && fn(...args));

const sortObjectKeys = (obj) => {
  const keys = Object.keys(obj);
  return keys.sort().reduce((r, k) => {
    r[k] = obj[k];
    return r;
  }, {});
};

// To guarantee that the value is always stringified in the same order
// For example, { a: 1, b: 2 } and { b: 2, a: 1 } should be the same when stringified
export const getStringifiedValue = (value) =>
  JSON.stringify(sortObjectKeys(value));
