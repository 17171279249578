import { PAGES } from '@shared/constants';
import getParams from '@shared/utils/getParams';

export const handleRedirectOfTargetUri = (targetUri: string): string => {
  if (targetUri.includes('https://')) {
    console.log(`Full redirect to other Quinyx target: ${targetUri}`);
    window.location.href = targetUri;
  } else {
    console.log(`Relative redirect to internal URI: ${targetUri}`);
    return targetUri;
  }
  return '/';
};

type ContextAndTarget = {
  context: string;
  targetPath: string;
  switchingAccount: boolean;
  customerAlias?: string;
};

export const separateAliasFromPath = () => {
  const { pathname, search } = window.location || {};
  const params = new URLSearchParams(search);

  const segments = pathname.split('/').filter(Boolean);
  for (let i = 0; i < segments.length; i += 1) {
    if (PAGES.includes(segments[i] || '')) {
      const targetPath =
        params.get('targetPath') || `/${segments.slice(i).join('/')}${search}`;
      return { customerAlias: i > 0 ? segments[0] : undefined, targetPath };
    }
  }

  return {
    customerAlias: segments[0],
    targetPath: '/',
  };
};

const getContext = (): string => {
  const search = getParams();
  const context = search.get('context');
  if (context === null || context.includes('javascript') || context === '/') {
    return 'quinyxweb';
  }
  return context;
};

const getSwitchAccount = (): boolean => {
  const search = getParams();
  const context = search.get('switchAccount');
  if (context === null || context.includes('javascript')) {
    return false;
  }
  return true;
};
export const getContextAndTargetPath = (): ContextAndTarget => {
  const context = getContext();
  const isSwitchingAccount = getSwitchAccount();
  const { customerAlias, targetPath } = separateAliasFromPath();

  const ct: ContextAndTarget = {
    context,
    targetPath,
    switchingAccount: isSwitchingAccount,
    customerAlias,
  };
  return ct;
};
