import React, { useEffect, useState } from 'react';

import { Router } from '@reach/router';

import LoginProvidersComponent from '@shared/components/LoginProviders';
import CheckEmailMessage from '@shared/components/LoginProviders/CheckEmailMessage';
import { REGIONS } from '@shared/constants';
import { isEmbeddedInApp } from '@shared/utils/webView';

import {
  fetchDomains as fetchDomainsApi,
  fetchLoginProviders as fetchLoginProvidersApi,
  fetchLoginTenants as fetchLoginTenantsApi,
  fetchRegion as fetchRegionApi,
} from '../../api/authentication';
import { useNavigate } from '../../utils/route';
import { useApi } from '../hooks/api';
import Fallback from '../Private/Fallback';
import MFA from './MultiFactorAuth';
import PasswordLogin from './PasswordLogin';
import { getContextAndTargetPath } from './utils';

const Login = ({
  onManagerPortalLogin,
  onStaffPortalLogin,
  managerPortalAuth: {
    isLoading: isLoadingManagerPortalAuth,
    isError: isErrorManagerPortalAuth,
  },
  managerPortalUser,
}) => {
  const navigate = useNavigate();
  const [emailOrProviderId, setEmailOrProviderId] = useState('');
  const [token, setToken] = useState();
  const [domainGroupIdSelected, setDomainGroupIdSelected] = useState();

  const [loginProviders, fetchLoginProviders] = useApi(fetchLoginProvidersApi);
  const [loginTenants, fetchLoginTenants] = useApi(fetchLoginTenantsApi);
  const [domains, fetchDomains] = useApi(fetchDomainsApi);

  const [{ data: region, isSuccess }, fetchRegion] = useApi(fetchRegionApi);

  useEffect(() => {
    if (!region) fetchRegion().catch((e) => e);
  }, [region, fetchRegion]);

  const handleRegionChange = ({ value }) => {
    fetchRegion(value);
  };
  const selectedRegion = region
    ? {
        value: region.regionId,
        label: REGIONS[region.regionName]?.name,
        short: region.regionName,
      }
    : {};
  const isEmail = emailOrProviderId.includes('@');

  const handleResetLoginTenants = () => {
    const contextAndPath = getContextAndTargetPath();

    navigate(
      `/?context=${contextAndPath.context}&targetPath=${contextAndPath.targetPath}`,
    );
    navigate(0);
  };

  const handleFetchLoginTenants = () => {
    const contextAndPath = getContextAndTargetPath();
    const loginType = isEmail ? 'email' : 'loginId';

    fetchLoginTenants(
      emailOrProviderId,
      contextAndPath.targetPath,
      loginType,
      contextAndPath.customerAlias,
      contextAndPath.context,
    )
      .then(({ data: { targetUrl } = {} } = {}) => {
        if (targetUrl && targetUrl.split('token=')[1]) {
          fetchDomains(targetUrl.split('token=')[1]);
          setToken(targetUrl.split('token=')[1]);
        }
      })
      .catch((e) => e);
  };
  const handleFetchLoginProviders = (domainGroupId, tokenToSend = token) => {
    setDomainGroupIdSelected(domainGroupId);
    fetchLoginProviders(domainGroupId, tokenToSend);
  };

  if (
    isLoadingManagerPortalAuth ||
    (!isErrorManagerPortalAuth && !managerPortalUser.id) ||
    isEmbeddedInApp()
  ) {
    return <Fallback />;
  }

  if (loginTenants?.data?.message) {
    return (
      <CheckEmailMessage
        message={loginTenants.data.message}
        url={loginTenants.data.targetUrl}
      />
    );
  }

  const emailFromProviders = loginProviders?.data?.email;
  const regionFromProvidersLabel =
    REGIONS[loginProviders?.data?.region?.regionName]?.name;

  return (
    <Router>
      <PasswordLogin
        path="/passwordLogin/*"
        onManagerPortalLogin={onManagerPortalLogin}
        onStaffPortalLogin={onStaffPortalLogin}
        onResetLoginProviders={handleResetLoginTenants}
        email={emailOrProviderId || emailFromProviders}
        region={regionFromProvidersLabel || selectedRegion.label}
        domainGroupId={domainGroupIdSelected || Math.random()}
      />
      <MFA
        path="/multiFactor/*"
        email={emailOrProviderId || emailFromProviders}
        region={regionFromProvidersLabel || selectedRegion.label}
        onManagerPortalLogin={onManagerPortalLogin}
        onStaffPortalLogin={onStaffPortalLogin}
        onResetLoginProviders={handleResetLoginTenants}
        domainGroupId={domainGroupIdSelected || Math.random()}
      />
      <LoginProvidersComponent
        default
        onEmailSubmit={handleFetchLoginTenants}
        loginTenants={domains}
        emailOrProviderId={emailOrProviderId}
        onChangeEmailOrProviderId={setEmailOrProviderId}
        isEmail={isEmail}
        region={selectedRegion}
        isSuccessRegion={isSuccess}
        onRegionChange={handleRegionChange}
        loginProviders={loginProviders}
        onDomainClick={handleFetchLoginProviders}
        onFetchDomains={fetchDomains}
      />
    </Router>
  );
};

export default Login;
