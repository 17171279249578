// ----------- Util from SO: https://stackoverflow.com/a/69019874/5804723
import { isNil } from '..';

// Types for Object.entries
type ObjectType = Record<PropertyKey, unknown>;
export type PickByValue<ObjT, ValueT> = // From https://stackoverflow.com/a/55153000
  Pick<
    ObjT,
    { [K in keyof ObjT]: ObjT[K] extends ValueT ? K : never }[keyof ObjT]
  >;
type ObjectEntries<ObjT> = // From https://stackoverflow.com/a/60142095
  {
    [K in keyof ObjT]: [keyof PickByValue<ObjT, ObjT[K]>, ObjT[K]];
  }[keyof ObjT][];

// Types for Object.fromEntries
// Data Types
type EntriesType =
  | [PropertyKey, unknown][]
  | ReadonlyArray<readonly [PropertyKey, unknown]>;

// Existing Utils
export type DeepWritable<ObjT> = {
  // Here we use Function to exclude functions from being made writable
  // as such we don't care about the typeguarding the arguments/returns
  // eslint-disable-next-line @typescript-eslint/ban-types
  -readonly [P in keyof ObjT]: ObjT[P] extends Function
    ? ObjT[P]
    : DeepWritable<ObjT[P]>;
};
export type UnionToIntersection<UnionT> = // From https://stackoverflow.com/a/50375286
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (UnionT extends any ? (k: UnionT) => void : never) extends (
    k: infer I,
  ) => void
    ? I
    : never;

// New Utils
type UnionObjectFromArrayOfPairs<ArrT extends EntriesType> =
  DeepWritable<ArrT> extends (infer R)[]
    ? R extends [infer key, infer val]
      ? { [prop in key & PropertyKey]: val }
      : never
    : never;
type MergeIntersectingObjects<ObjT> = { [key in keyof ObjT]: ObjT[key] };
type EntriesToObject<ArrT extends EntriesType> = MergeIntersectingObjects<
  UnionToIntersection<UnionObjectFromArrayOfPairs<ArrT>>
>;

export type ObjectKeys<ObjT extends ObjectType> = {
  [K in keyof ObjT]: K;
}[keyof ObjT];

export function getTypedObjectKeys<ObjT extends ObjectType>(obj: ObjT) {
  return Object.keys(obj) as ObjectKeys<ObjT>[];
}

export function getTypedObjectEntries<ObjT extends ObjectType>(
  obj: ObjT,
): ObjectEntries<ObjT> {
  return Object.entries(obj) as ObjectEntries<ObjT>;
}

export function createTypedObjectFromEntries<ArrT extends EntriesType>(
  arr: ArrT,
): EntriesToObject<ArrT> {
  return Object.fromEntries(arr) as EntriesToObject<ArrT>;
}

export function getMatchingKeys<
  O extends ObjectType,
  K extends ReadonlyArray<PropertyKey>,
>(obj: O, keys: K): (keyof O & K[number])[] {
  return keys.filter((key) => key in obj);
}

// Debug utils

export type Expand<T> = T extends infer O ? { [K in keyof O]: O[K] } : never;

export type ExpandRecursively<T> = T extends object
  ? T extends infer O
    ? { [K in keyof O]: ExpandRecursively<O[K]> }
    : never
  : T;

export type ObjectItemUnion<O> = O[keyof O];
export type ObjectItem<O, T extends keyof O> = O[T];
export type PropertyByKey<O, K extends keyof O> = {
  [P in K]: O[P];
}[K];

export type TupleToUnion<T extends Array<unknown> | ReadonlyArray<unknown>> = {
  [P in keyof T]: T[P];
}[number];

// TODO Michal Sadowski: This currently fails on unions of array types
export const hasAtLeastOneItem = <T>(array?: T[]): array is [T, ...T[]] =>
  !isNil(array) && !isNil(array[0]);

export const isKeyOfObject = (
  object: Record<PropertyKey, unknown>,
  key: PropertyKey,
): key is keyof typeof object => key in object;

export const isInTuple = <A extends ReadonlyArray<K>, K>(
  tuple: A,
  key: K,
): key is A[number] => tuple.includes(key);
