/* eslint-disable no-console */
import { FieldValues, Path, UseFormReturn } from 'react-hook-form';

import { notify } from '@shared/components/Notifications/utils';
import {
  isTransformedApiError,
  isTransformedMainMessageError,
} from '@shared/containers/hooks/api/utils';
import { getTypedObjectKeys, ObjectKeys } from '@shared/utils/typeUtils';

import { FormErrors } from '../types';

// Transforms our useApi errors into something react-hook-form can use.
const getTransformedApiErrors = <T extends FieldValues>(
  fieldErrorDictionary: Record<string, string>,
  fieldKeys: ObjectKeys<T>[],
) => {
  const isFieldKey = (key: string): key is Path<T> => fieldKeys.includes(key);
  return Object.keys(fieldErrorDictionary).reduce(
    (errors: FormErrors<T>, name) => {
      if (isFieldKey(name)) {
        errors.push({
          name,
          error: {
            type: 'custom',
            message: fieldErrorDictionary[name],
          },
        });
      } else if (__DEV__) {
        console.error(
          'Error passed to handleFormApiError with key that is not part of the form. Key:',
          name,
        );
      }
      return errors;
    },
    [],
  );
};

export const handleFormApiError = <T extends FieldValues>(
  methods: UseFormReturn<T>,
  error: unknown,
  shouldFocus?: boolean,
): void => {
  if (error instanceof Error || !isTransformedApiError(error)) throw error;

  if (isTransformedMainMessageError(error)) {
    notify(error.errors.mainMessage, 'error');
    return;
  }

  const fieldKeys = getTypedObjectKeys(methods.getValues());
  if (!fieldKeys.some((field) => field in error.errors) && __DEV__) {
    console.error(
      'No errors passed to handleFormApiError are part of the form',
      { errors: error.errors, form: methods.getValues() },
    );
    return;
  }

  const transformedErrors = getTransformedApiErrors(error.errors, fieldKeys);
  transformedErrors.forEach(({ name, error: errorOption }) =>
    methods.setError(
      name,
      errorOption,
      ...(shouldFocus ? [{ shouldFocus }] : []),
    ),
  );
};
